define("Automatons/nodes/2629", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2629,
    type: "nodes",
    attributes: {
      name: "VF_PreChat_Retail_Initial_Credit_Assessment_Survey_Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "credit_status",
          name: "credit_status",
          type: "select",
          label: "What status is the Credit Application in?<span class='asterisk'>*</span>",
          options: ["No Application submitted", "Decline", "Refer"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "no_app_submiited_option",
          type: "text",
          dynamic: {
            check: "credit_status",
            value: "No Application submitted"
          },
          label: "<p style='font-size: 0.9rem; font-weight: bold'>Please refer to your BAU process</p>"
        }, {
          id: "decline_option",
          type: "text",
          dynamic: {
            check: "credit_status",
            value: "Decline"
          },
          label: "<p style='font-size: 0.9rem; font-weight: bold'>Please see <a href='https://www.vodafone.co.uk/privacy/credit-checks' target='_blank' onclick='Inq.closeChat();' style='text-decoration: underline; font-weight: bold'>Credit checks - Vodafone</a></p>"
        }, {
          id: "query_type",
          type: "select",
          dynamic: {
            check: "credit_status",
            value: "Refer"
          },
          label: "What is the query type?<span class='asterisk'>*</span>",
          options: ["Consumer", "Business"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "decline_option",
          type: "text",
          dynamic: {
            check: "query_type",
            value: "Business"
          },
          label: "<div><p style='margin: 25px 5px !important'>The Business Credit Assessment team can now be contacted via phone 08:30-18:30 Mon-Fri and 09:00-17:30 Sat & Sun.</p> <div style='margin: 0px 10px;'><ul><li style='display: list-item; list-style: disc outside none; margin-left: 1em; margin-bottom: 5px;'>Business Partners please call 0333 3044 544 opt 4.</li><li style='display: list-item; list-style: disc outside none; margin-left: 1em; margin-bottom: 5px'>Retail Colleagues please call 0333 3044 000 opt 1, opt 1, opt 2.</li><li style='display: list-item; list-style: disc outside none; margin-left: 1em; margin-bottom: 5px'>Internal Vodafone colleagues please call 8000091.</li></ul></div></div>"
        }, {
          id: "dept_name",
          type: "text",
          dynamic: {
            check: "query_type",
            value: "Consumer"
          },
          label: "What department are you calling from?<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is required"
        }, {
          id: "name",
          type: "text",
          dynamic: {
            check: "query_type",
            value: "Consumer"
          },
          label: "What is your name?<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is required"
        }, {
          id: "ctn_or_ban",
          type: "text",
          dynamic: {
            check: "query_type",
            value: "Consumer"
          },
          label: "CTN or BAN?<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is required"
        }, {
          id: "cust_name",
          type: "text",
          dynamic: {
            check: "query_type",
            value: "Consumer"
          },
          label: "Customer Name<span class='asterisk'>*</span>",
          required: true,
          requiredError: "This is required"
        }, {
          id: "cust_type",
          type: "select",
          dynamic: {
            check: "query_type",
            value: "Consumer"
          },
          label: "Is the customer new or existing?<span class='asterisk'>*</span>",
          options: ["New", "Existing"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }, {
          id: "dpa",
          type: "select",
          dynamic: {
            check: "query_type",
            value: "Consumer"
          },
          label: "Have they passed DPA?<span class='asterisk'>*</span>",
          options: ["Yes", "No"],
          placeholder: "Please Choose",
          required: true,
          requiredError: "This is required"
        }],
        footerText: "Please note this conversation may be recorded for training and quality purposes. For more information on how we handle your personal data please visit our privacy policy at vodafone.co.uk/privacy.",
        heading: "Welcome to Credit Assessment",
        next: "submit",
        requiredText: "*Fields marked with an asterisk are required.",
        subheading: "To get started, please answer a few questions."
      },
      initialFocusElement: "#inq-2062-name-label",
      onEntry: function onEntry() {
        var deviceType = api.flashVars.deviceType;
        if (deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay !== true) {
          api.resize(300, 490);
        }
        // CMRGEN-31015 START
        // let $viewport = api.automaton.Automaton.View.$viewport;
        // var automatonId = api.automaton.id;
        // var obsTarget = $viewport[0];

        // var infoMessage = $('<div style="display: none;"><p>The Business Credit Assessment team can now be contacted via phone 08:30-18:30 Mon-Fri and 09:00-17:30 Sat & Sun.</p><ul><li style=" display: list-item; list-style: disc outside none; margin-left: 1em; ">Business Partners please call 0333 3044 544 opt 4.</li><li style=" display: list-item; list-style: disc outside none; margin-left: 1em; ">Retail Colleagues please call 0333 3044 000 opt 1, opt 1, opt 2.</li><li style=" display: list-item; list-style: disc outside none; margin-left: 1em; ">Internal Vodafone colleagues please call 8000091.</li></ul></div>');
        // infoMessage.addClass('inq-' + automatonId + '-formify-subheading');

        // function createUIElements() {
        //   let formifyHeader = $('div[acif-template="formify-header"]', $viewport);
        //   let submitButton = $('button[type="submit"]', $viewport);
        //   let queryType = $('select[name="query_type"]', $viewport);
        //   formifyHeader.after(infoMessage);
        //   queryType.bind('change', function(evt) {
        //     var selectedValue = $(this).val();
        //     if (["Business"].indexOf(selectedValue) > -1) {
        //       infoMessage.show();
        //       submitButton.hide();
        //     } else {
        //       infoMessage.hide();
        //       submitButton.show();
        //     }
        //   });
        // }

        // var observer = new MutationObserver(function(mutations, observer) {
        //   var inputFieldsCount = $('input', $viewport).length;
        //   var btnCount = $('button', $viewport).length;
        //   if (inputFieldsCount > 0 && btnCount > 0) {
        //     createUIElements();
        //     observer.disconnect();
        //   }
        // });
        // observer.observe(obsTarget, {
        //   subtree: true,
        //   childList: true
        // });
        // CMRGEN-31015 END
      },
      proto: "node::2411",
      template: "template::2067",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.flashVars.deviceType !== 'Phone' && api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2296"
        },
        submit: [{
          condition: "api.forms['credit_status'].indexOf('Refer') > -1 && api.forms['query_type'].indexOf('Consumer') > -1",
          target: "node::2486"
        }, {
          condition: "api.forms['credit_status'].indexOf('Refer') > -1 && api.forms['query_type'].indexOf('Business') > -1",
          target: "node::2487"
        }]
      }
    }
  };
});